import { CALL_API_METHODS } from "repoV2/constants/apis";
import { SESSION_STORAGE_KEYS } from "repoV2/constants/common/storage/sessionStorage";
import {
    getCookieValue,
    setCookie,
} from "repoV2/features/Common/modules/Storage/modules/Cookies/utils/Cookies.utils";
import { initFbPixel } from "repoV2/utils/common/analytics/fbPixel/initFbPixel";
import { getUtmParams } from "repoV2/utils/common/analytics/utmParams";
import { isRequestSuccessful } from "repoV2/utils/common/api/api";
import { callApi } from "repoV2/utils/common/api/callApi";
import { checkIsArrayEmpty } from "repoV2/utils/common/dataTypes/array";
import { logError } from "repoV2/utils/common/error/error";
import {
    getSessionStorageItem,
    setSessionStorageItem,
} from "repoV2/utils/common/storage/getterAndSetters";
import { getQueryParam } from "repoV2/utils/urls&routing/urlParams";
import { v4 as uuidv4 } from "uuid";
import { isBrowser } from "repoV2/utils/common/render/window";
import { META_PIXEL_API_URLS } from "./MetaPixel.api";
import {
    META_ANALYTICS_COOKIE_KEYS,
    META_ANALYTICS_QUERY_PARAMS,
    META_ANALYTICS_REGEX,
} from "./MetaPixel.constants";
import { IMetaPixel } from "./MetaPixel.interfaces";

/**
 * Generates a UUID for meta pixel event_id and stores it in sessionStorage.
 * @returns {string} The newly generated meta pixel UUID.
 */
const generateNewBrowserMetaPixelUuid = () => {
    const newPixelUuid = uuidv4();
    const sessionStorageMetaPixelData = {
        uuid: newPixelUuid,
        timestamp: Date.now(),
    };
    setSessionStorageItem(
        SESSION_STORAGE_KEYS.META_PIXEL_UUID_DATA,
        JSON.stringify(sessionStorageMetaPixelData)
    );
    return newPixelUuid;
};

/**
 * Retrieves the UUID for meta pixel event_id from sessionStorage.
 * If the UUID is not found or more than 5 minutes have elapsed since its creation,
 * a new UUID is generated and stored in sessionStorage.
 * @returns {string} The browser meta pixel UUID.
 */
export const getBrowserMetaPixelUuid = () => {
    const pixelUuidValue = getSessionStorageItem(
        SESSION_STORAGE_KEYS.META_PIXEL_UUID_DATA
    );

    if (pixelUuidValue) {
        const pixelUuidData = JSON.parse(pixelUuidValue);
        const { uuid, timestamp } = pixelUuidData;

        const millis = Date.now() - timestamp;
        const minutesElapsed = Math.floor(millis / (1000 * 60));

        if (minutesElapsed < 5) return uuid;
    }

    return generateNewBrowserMetaPixelUuid();
};

/**
 * Fetches the creator meta pixel token by triggering a conversion API event.
 * If Conversion API event is validated successfully on backend, returns creator
 * meta pixel token which will be used to trigger browser meta pixel event.
 * @param {IMetaPixel.IConversionAPIEventData} eventData - The conversion API event data.
 * @returns {Promise<{ custom_pixel_token?: string; is_valid_event: boolean }>} A promise that resolves with the meta pixel token and event validity.
 */
const fetchMetaPixelToken = (
    eventData: IMetaPixel.IConversionAPIEventData
): Promise<{ custom_pixel_token?: string; is_valid_event: boolean }> =>
    new Promise((resolve, reject) => {
        callApi({
            url: META_PIXEL_API_URLS.POST_CONVERSION_API_EVENT,
            data: {
                ...eventData,
                event_source_url: window.location.href,
                utm_info: getUtmParams(),
            },
            extraOptions: {
                appendExlyBackendHeaders: true,
            },
            method: CALL_API_METHODS.POST,
        })
            .then((apiResponse: any) => {
                const { status, data, message } = apiResponse?.data || {};
                if (!isRequestSuccessful(status)) throw new Error(message);
                resolve(data);
            })
            .catch(reject);
    });

/**
 * Posts a meta pixel browser event to Facebook.
 * @param {string} pixelToken - The meta pixel token.
 * @param {IMetaPixel.IConversionAPIEventData} eventData - event data.
 */
const postMetaPixelBrowserEvent = (
    pixelToken: string,
    eventData: IMetaPixel.IConversionAPIEventData
) => {
    initFbPixel({ id: pixelToken });

    if (window?.fbq) {
        const {
            event_name: eventName,
            event_id: eventID,
            custom_data: customData,
        } = eventData;
        const {
            value: price,
            content_category: contentCategory,
            content_name: contentName,
            currency,
            num_items: quantity,
        } = customData ?? {};

        const isPuppeteer =
            isBrowser() &&
            window?.location.search.includes("is_puppeteer=true");

        window?.fbq(
            "track",
            eventName,
            {
                value: price,
                content_category: contentCategory,
                content_name: contentName,
                currency,
                num_items: quantity,

                // dummy key inserted for testing
                is_puppeteer: isPuppeteer,
            },
            { eventID }
        );

        if (isPuppeteer) {
            window?.fbq(
                "track",
                "InitiateCheckout",
                {
                    // dummy key inserted for testing
                    is_puppeteer: isPuppeteer,
                },
                { eventID }
            );
        }
    }
};

/**
 * Posts a Meta Conversion API and Pixel event
 * @param {IMetaPixel.IConversionAPIEventData} eventData - event data.
 */
export const callPostMetaEvent = async (
    eventData: IMetaPixel.IConversionAPIEventData
) => {
    // required props validation
    if (!eventData?.sub_domain || !eventData?.event_name) return;

    try {
        const {
            is_valid_event: isValidEvent,
            custom_pixel_token: customPixelToken,
        } = await fetchMetaPixelToken({
            ...eventData,
            event_id:
                eventData?.event_id ??
                `${Date.now()}_${getBrowserMetaPixelUuid()}`, // default event_id
        });
        const shouldPostMetaPixelBrowserEvent =
            isValidEvent && customPixelToken;

        if (shouldPostMetaPixelBrowserEvent)
            postMetaPixelBrowserEvent(customPixelToken, eventData);
    } catch (error) {
        logError({
            error,
            when: "calling callPostMetaEvent",
            occuredAt:
                "repoV2/features/Common/Analytics/modules/MetaPixel/MetaPixel.utils.ts",
        });
    }
};

/**
 * Generates a new 'fbc' cookie value based on the provided Facebook click ID (fbClickId).
 * @param fbClickId The Facebook click ID (fbclid) to include in the 'fbc' cookie value.
 * @returns The generated 'fbc' cookie value or undefined if fbClickId is not provided.
 * ref: https://developers.facebook.com/docs/marketing-api/conversions-api/parameters/fbp-and-fbc/
 */
const generateFbcCookieValue = (fbClickId: string) =>
    fbClickId ? `fb.1.${Date.now()}.${fbClickId}` : undefined;

/**
 * Retrieves or generates the 'fbc' cookie value based on Facebook click ID (fbclid) query parameter.
 * If the 'fbc' cookie exists, it returns its value. If not, it checks for fbclid in query parameters
 * and generates a new 'fbc' cookie value if fbclid is present.
 * @returns The 'fbc' cookie value if found or generated, otherwise undefined.
 * ref: https://developers.facebook.com/docs/marketing-api/conversions-api/parameters/fbp-and-fbc/
 */
export const getFbcCookie = () => {
    const fbc = getCookieValue(META_ANALYTICS_COOKIE_KEYS.fbc);

    if (fbc) return fbc;

    const fbClickId = getQueryParam(META_ANALYTICS_QUERY_PARAMS.fbclid);

    if (fbClickId) {
        const newFbcCookie = generateFbcCookieValue(fbClickId);

        if (newFbcCookie) {
            setCookie({
                key: META_ANALYTICS_COOKIE_KEYS.fbc,
                value: newFbcCookie,
                days: 90,
            });

            return newFbcCookie;
        }
    }

    return undefined;
};

/**
 * Normalizes a meta customer information parameter.
 *
 * @param value - A string or an array of strings representing the meta customer information.
 * @returns If the input is falsy, returns `undefined`.
 *          If the input is an array, filters out empty items, trims whitespace, and converts to lowercase.
 *          If the input is a string, trims whitespace and converts to lowercase.
 */
export const normalizeMetaCustomerInfoParam = (value?: string | string[]) => {
    if (!value) return undefined;

    if (Array.isArray(value))
        return checkIsArrayEmpty(value)
            ? undefined
            : value
                  .filter(item => item)
                  .map(item => item?.trim().toLowerCase());

    return value?.trim().toLowerCase();
};

/**
 * Normalizes a meta customer phone number.
 *
 * @param value - A string or an array of strings representing the phone numbers.
 * @returns If the input is falsy, returns `undefined`.
 *          If the input is an array, removes plus signs and spaces from each item.
 *          If the input is a string, removes plus signs and spaces.
 */
export const normalizeMetaCustomerPhone = (value?: string | string[]) => {
    if (!value) return undefined;

    if (Array.isArray(value))
        return value.map(item =>
            item?.replace(META_ANALYTICS_REGEX.REMOVE_PLUS_AND_SPACES, "")
        );

    return value?.replace(META_ANALYTICS_REGEX.REMOVE_PLUS_AND_SPACES, "");
};

/**
 * Extracts the first and last name initials from a given name string.
 *
 * @param name - The full name string.
 * @returns An object containing the first name and the last name (if present).
 */
const getNameInitials = (name: string) => {
    const names = name?.trim()?.toLowerCase()?.split(" ") ?? [];
    const [firstName] = names;
    const lastName = names.length > 1 ? names[names.length - 1] : undefined;
    return { firstName, lastName };
};

/**
 * Normalizes a meta customer name.
 *
 * @param value - A string or an array of strings representing the names.
 * @returns An object containing arrays of first names and last names.
 *          If the input is falsy, returns empty arrays for first names and last names.
 *          If the input is an array, processes each item to extract and normalize first and last names.
 *          If the input is a single string, processes it to extract and normalize first and last names.
 */
export const normalizeMetaCustomerNames = (value?: string | string[]) => {
    if (!value) return { firstNames: [], lastNames: [] };

    if (Array.isArray(value)) {
        const firstNames = [] as string[];
        const lastNames = [] as (string | undefined)[];

        value.forEach(item => {
            const { firstName, lastName } = getNameInitials(item);
            firstNames.push(firstName);
            lastNames.push(lastName);
        });

        return {
            firstNames: firstNames.filter(item => item),
            lastNames: lastNames.filter(item => item) as string[],
        };
    }

    const { firstName, lastName } = getNameInitials(value);
    return { firstNames: [firstName], lastNames: lastName ? [lastName] : [] };
};
